<template>
   <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
         <div class="container-fluid">
            <div class="row mb-2">
               <div class="col-sm-6">
                  <h1 class="m-0">{{ $t('partner_companies') }}</h1>
               </div><!-- /.col -->
               <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                     <li class="breadcrumb-item active">{{ $t('dashboard') }}</li>
                     <li class="breadcrumb-item active">{{ $t('partner_companies') }}</li>
                     <li v-if="isEditing" class="breadcrumb-item active">{{ $t('edit_partner_company') }}</li>
                     <li v-else class="breadcrumb-item active">{{ $t('create_partner_company') }}</li>
                  </ol>
               </div><!-- /.col -->
            </div><!-- /.row -->
         </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
         <div class="container-fluid">

            <div class="row">
               <div class="col-md-12">
                  <div class="card card-outline card-info">

                     <div v-if="isLoading" class="d-flex justify-content-center">{{ $t('loading') }}</div>

                     <div v-else>
                        <!-- card-header -->
                        <div class="card-header">
                           <h3 class="card-title">
                              {{ isEditing === false ? $t('create_partner_company') : $t('edit_partner_company') }}
                           </h3>
                        </div>

                        <form @submit.prevent="save">
                           <div class="card-body">
                              <div class="row">
                                 <div class="col-6">
                                    <div class="form-group mb-4">
                                       <label for="exampleInputEmail1">{{ $t('name') }}*</label>
                                       <input type="text" v-model="partnerCompany.name" class="form-control">
                                    </div>
                                 </div>

                                 <div class="col-6">
                                    <div class="form-group">
                                       <label>{{ $t('branch_of_activity') }}*</label>
                                       <VueMultiselect
                                          v-model="branchesSelected"
                                          placeholder="Adicionar ou buscar um ramo de atuação"
                                          tag-placeholder="Pressione o enter ou clique para adicionar um novo ramo de atuação"
                                          select-label="Pressione o enter ou clique para selecionar"
                                          deselect-label="Pressione o enter ou clique para remover"
                                          label="name"
                                          track-by="uuid"
                                          :options="branchesOptions"
                                          :multiple="true"
                                          :taggable="true"
                                          @tag="addBranch"
                                          @remove="removeBranch"
                                       ></VueMultiselect>
                                    </div>
                                 </div>

                                 <div class="col-12">
                                    <div class="form-group mb-4">
                                       <label for="exampleInputEmail1">{{ $t('presentation_text') }}</label>
                                       <textarea v-model="partnerCompany.presentationText" class="form-control" ></textarea>
                                    </div>
                                 </div>

                                 <div class="col-12">
                                    <div class="form-group">
                                       <label>{{ $t('benefits') }}*</label>
                                       <VueMultiselect
                                          v-model="benefitsSelected"
                                          placeholder="Adicionar ou buscar um benefício"
                                          tag-placeholder="Pressione o enter ou clique para adicionar um novo benefício"
                                          select-label="Pressione o enter ou clique para selecionar"
                                          deselect-label="Pressione o enter ou clique para remover"
                                          label="name"
                                          track-by="uuid"
                                          :options="benefitsOptions"
                                          :multiple="true"
                                          :taggable="true"
                                          @tag="addBenefit"
                                          @remove="removeBenefit"
                                       ></VueMultiselect>
                                    </div>
                                 </div>

                                 <div class="col-4 my-2">
                                    <label>{{ $t('image_cover') }}*</label>
                                    <div>
                                       <img
                                          v-if="partnerCompany.imageCoverUrl"
                                          :src="partnerCompany.imageCoverUrl"
                                          :alt="partnerCompany.name"
                                          height="150"
                                          width="300"
                                       />
                                       <a v-else style="border-radius: 50%" class="btn btn-app">
                                          <i class="fas fa-image"></i> {{ $t('image') }}
                                       </a>
                                       <div class="row input-file-container">
                                          <input type="file" id="meuInputFile" class="input-file" @change="onImageChoose" style="display: none;" />
                                          <label for="meuInputFile" class="input-file-trigger btn btn-outline-secondary btn-sm mx-4 mt-2 mb-3">
                                             {{ alreadyIncludeImageCover ? $t('change') : $t('insert') }}
                                          </label>
                                       </div>
                                    </div>
                                 </div>

                                 <div class="col-3 my-3">
                                    <div class="form-group upload-btn-wrapper">
                                       <label>{{ $t('presentation_images') }}*</label>
                                       <button @click.prevent="showModals" class="btn btn-outline-secondary btn-sm mx-4 mt-2 mb-3 text-bold">{{ $t('manage_images') }}</button>
                                    </div>
                                 </div>

                                 <div class="col-5 mt-3">
                                    <div class="form-group">
                                       <div class="custom-control custom-switch pt-2 custom-switch-off-danger custom-switch-on-success">
                                          <input type="checkbox" v-model="partnerCompany.isHighlighted" class="custom-control-input" id="customSwitchHighlighted">
                                          <label class="custom-control-label" for="customSwitchHighlighted">
                                             {{ partnerCompany.isHighlighted == true ? $t('outstanding_with_company') : $t('unremarkable_company') }}
                                          </label>
                                       </div>
                                    </div>
                                 </div>

                                 <div class="col-3">
                                    <div class="form-group mb-4">
                                       <label for="exampleInputEmail1">{{ $t('phone') }}</label>
                                       <input
                                          type="text"
                                          placeholder="(33) 99999-9999 ou (33) 3521-9999"
                                          v-model="partnerCompany.phoneNumber"
                                          class="form-control"
                                       >
                                    </div>
                                 </div>

                                 <div class="col-3">
                                    <div class="form-group mb-4">
                                       <label for="exampleInputEmail1">{{ $t('zip_code') }}*</label>
                                       <input
                                          type="text"
                                          v-mask="'00000-000'"
                                          v-model="partnerCompany.zipCode"
                                          class="form-control"
                                       >
                                    </div>
                                 </div>

                                 <div class="col-3">
                                    <div class="form-group mb-4">
                                       <label for="exampleInputEmail1">{{ $t('state') }}*</label>
                                       <input type="text" v-model="partnerCompany.state" disabled class="form-control">
                                    </div>
                                 </div>

                                 <div class="col-3">
                                    <div class="form-group mb-4">
                                       <label for="exampleInputEmail1">{{ $t('city') }}*</label>
                                       <input type="text" v-model="partnerCompany.city" disabled class="form-control">
                                    </div>
                                 </div>

                                 <div class="col-6">
                                    <div class="form-group mb-4">
                                       <label for="exampleInputEmail1">{{ $t('neighborhood') }}*</label>
                                       <input type="text" v-model="partnerCompany.neighborhood" disabled class="form-control">
                                    </div>
                                 </div>

                                 <div class="col-4">
                                    <div class="form-group mb-4">
                                       <label for="exampleInputEmail1">{{ $t('street') }}*</label>
                                       <input type="text" v-model="partnerCompany.street" class="form-control">
                                    </div>
                                 </div>

                                 <div class="col-2">
                                    <div class="form-group mb-4">
                                       <label for="exampleInputEmail1">{{ $t('number') }}*</label>
                                       <input type="text" v-model="partnerCompany.number" class="form-control">
                                    </div>
                                 </div>

                                 <div class="col-12">
                                    <label for="exampleInputEmail1">{{ $t('social_medias') }}</label> <br />
                                    <div class="form-group mx-2">
                                       <div>
                                          <div v-for="(socialMedia, idx) in partnerCompany.socialMedias" :key="idx">
                                             <hr>
                                             <span class="font-italic">{{ socialMedia.type }}: {{socialMedia.link}}</span>
                                             <div class="row justify-content-end">
                                                <button type="button" @click="removeSocialMedia(socialMedia.type)" class="btn btn-xs btn-danger"> <i class="fas fa-trash-alt"></i>
                                                   {{ $t('exclude') }}
                                                </button>
                                             </div>
                                          </div>
                                       </div>
                                    </div>

                                    <div class="justify-content-start my-1">
                                       <div class="row">
                                          <div class="col-2">
                                             <select v-model="selectedSocialMedia" class="form-control">
                                                <option disabled selected>{{ $t('choose_the_type_of_social_media') }}</option>
                                                <option value="facebook">facebook</option>
                                                <option value="instagram">instagram</option>
                                                <option value="linkedin">linkedin</option>
                                                <option value="tiktok">tiktok</option>
                                                <option value="twitter">twitter</option>
                                                <option value="site">site</option>
                                                <option value="whatsapp">whatsapp</option>
                                             </select>
                                          </div>
                                          <div class="col-5">
                                             <div style="display: flex; flex-direction: row">
                                                <input
                                                   type="text"
                                                   v-model="socialMedia"
                                                   :placeholder="[
                                                      selectedSocialMedia === 'whatsapp' ? 'https://wa.me/+5533999999999' :
                                                       selectedSocialMedia !== null ? `Url do ${selectedSocialMedia}` : '']"
                                                   class="form-control mx-2">
                                                <button
                                                   @click="addSocialMedia()"
                                                   type="button"
                                                   class="btn btn-default mx-2 btn-sm text-bold">
                                                   {{ $t('add') }}
                                                </button>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                              </div>
                              <!-- ./row -->
                           </div>
                           <div class="card-footer">
                              <div class="row justify-content-end">
                                 <SaveButton
                                    :buttonName="saveButtonName"
                                    :isSaving="isSaving"
                                 >
                                 </SaveButton>
                              </div>
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
               <!-- /.col-->
            </div>
            <!-- ./row -->
         </div>

         <div class="modals">
            <div v-if="showModal" class="modal-mask">
               <div class="modal-wrapper">
                  <div class="modal-container">
                     <div class="modal-header">
                        <h3>{{ $t('manage_images') }}</h3>
                        <br/>
                        <span class="font-italic text-sm">Obs: As imagens só seram salvas após fechar esse modal e clicar em (Salvar).</span>
                        <button class="close text-danger" @click="closeModal">&times;</button>
                     </div>
                     <div class="modal-body">

                        <div v-for="(imageUrl, idx) in partnerCompany.presentationImagesUrl" :key="idx" class="modal-div">
                           <img
                              class="modal-image"
                              :src="imageUrl"
                              width="150"
                              height="100"
                           >
                           <span @click="deletePresentationImages(imageUrl)" class="modal-span text-bold fa fa-trash"></span>
                        </div>

                     </div>
                     <div class="modal-footer">
                        <div class="form-group upload-btn-wrapper">
                           <button class="btn btn-outline-secondary btn-sm mx-4 mt-2 mb-3 text-bold">Nova Imagem</button>
                           <input type="file" @change="handlePresentationImages" multiple>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SaveButton from "../../../components/SaveButton.vue";
import { v4 as uuidV4 } from "uuid";
import { useToast } from "vue-toastification";
import partnerCompanyService from "../services/partner-company-service";
import VueMultiselect from "vue-multiselect";
import { getCep } from "../services/via-cep-api";
import {storageRef} from "../../../../../plugins/firebase";
const brStates = require('../../../../../utils/br-states.json');

export default {
   name: "PartnerCompaniesForm",
   setup() {
      const toast = useToast()
      return { toast }
   },
   components: {
      SaveButton,
      VueMultiselect
   },
   data () {
      return {
         partnerCompany: {
            uuid: uuidV4(),
            name: null,
            presentationText: null,
            branchesId: [],
            benefitsId: [],
            imageCover: null,
            imageCoverUrl: null,
            imageCoverUrlToDelete: null,
            presentationImages: [],
            presentationImagesUrl: [],
            presentationImagesUrlToDelete: [],
            socialMedias: [],
            isHighlighted: false,
            zipCode: null,
            state: null,
            city: null,
            neighborhood: null,
            street: null,
            number: null,
            phoneNumber: null,
            newBranches: [],
            newBenefits: [],
            benefitsSelectedToSaveInBenefitCollection: []
         },
         alreadyIncludeImageCover: false,
         branchesSelected: [],
         branchesOptions: [],
         benefitsSelected: [],
         benefitsOptions: [],
         presentationImagesCount: 0,
         isLoading: false,
         isEditing: false,
         isSaving: false,
         errors: [],
         selectedSocialMedia: null,
         socialMedia: null,
         showModal: false,
         activeIndex: 0
      }
   },
   computed: {
      ...mapState({
         benefits: state => state.benefit.benefits.allBenefits,
         branches: state => state.branch.branches.allBranches,
         allPartnerCompaniesBenefitsRelation: state => state.partnerCompany.partnerCompanies.allPartnerCompaniesBenefitsRelation
      }),
      saveButtonName () {
         if (this.isEditing)
            return 'edit';
         else
            return 'save';
      }
   },
   watch: {
      "partnerCompany.zipCode" (newCep) {
         if(newCep.length === 9 ){
            const cep = newCep.replace("-", "");
            getCep(cep).then((response) => {

               if(response.data.erro){
                  this.toast.warning(this.$t("api_address_error"));
               }else{

                  this.partnerCompany.street = response.data.logradouro;
                  this.partnerCompany.neighborhood = response.data.bairro;
                  this.partnerCompany.city   = response.data.localidade;
                  const state = brStates.find((state) => state.uf.toUpperCase() === response.data.uf.toUpperCase());
                  this.partnerCompany.state = state?.name ?? "";
               }
            }).catch(() => {
               this.toast.error(this.$t("api_address_error"));
            });
         }
      }
   },
   async created() {
      this.isLoading = true;

      try {
         await this.getAllBranches();
         await this.getAllBenefits();

         this.branchesOptions = Object.assign(this.branchesOptions, this.branches);
         this.benefitsOptions = Object.assign(this.benefitsOptions, this.benefits);

         if (this.$route.params.uuid){
            this.isEditing = true;
            this.alreadyIncludeImageCover = true;

            const { data: response } = await partnerCompanyService.get(this.$route.params.uuid);
            this.partnerCompany = response;
            this.partnerCompany.imageCover = null;
            this.partnerCompany.imageCoverUrlToDelete = this.partnerCompany.imageCoverUrl;
            this.partnerCompany.presentationImages = [];
            this.partnerCompany.newBranches = [];
            this.partnerCompany.newBenefits = [];
            this.partnerCompany.benefitsSelectedToSaveInBenefitCollection = [];
            this.partnerCompany.presentationImagesUrlToDelete = [];

            this.presentationImagesCount = this.partnerCompany.presentationImagesUrl.length;

            //include branches
            response.branchesId.forEach((branch) => {
               const branchFounded = this.branchesOptions.find((item) => item.uuid === branch);
               if (branchFounded !== undefined)
                  this.branchesSelected.push(branchFounded);
            });

            //include benefits
            response.benefitsId.forEach((benefit) => {
               const benefitFounded = this.benefitsOptions.find((item) => item.uuid === benefit);
               if (benefitFounded !== undefined)
                  this.benefitsSelected.push(benefitFounded);
            });
         }
      } finally {
         this.isLoading = false;
      }
   },
   methods: {
      ...mapActions(['getAllBenefits', 'getAllBranches', 'getAllPartnerCompaniesBenefitsRelation']),
      addSocialMedia () {
         const socialMedia = {
            type: this.selectedSocialMedia,
            link: this.socialMedia
         };

         const found = this.partnerCompany.socialMedias.find(item => item.type === socialMedia.type);
         if (found === undefined)
            this.partnerCompany.socialMedias.push(socialMedia);
      },
      addBranch (newBranch) {
         const branch = {
            name: newBranch,
            uuid: uuidV4()
         }
         this.branchesOptions.push(branch)
         this.branchesSelected.push(branch)
      },
      addBenefit (newBenefit) {
         const benefit = {
            name: newBenefit,
            uuid: uuidV4(),
            partnerCompaniesUuid: []
         }
         this.benefitsOptions.push(benefit)
         this.benefitsSelected.push(benefit)
      },
      closeModal() {
         this.showModal = false;
      },
      deletePresentationImages (src) {
         const confirmation = confirm(this.$t('are_you_sure_you_want_delete_this_image'));
         if (confirmation) {
            this.partnerCompany.presentationImagesUrl = this.partnerCompany.presentationImagesUrl.filter(item => item !== src);
            this.partnerCompany.presentationImages = this.partnerCompany.presentationImages.filter(item => item !== src);

            if ('https' === src.slice(0, 5)){
               this.partnerCompany.presentationImagesUrlToDelete.push(src);
            }
         }
      },
      handlePresentationImages (event) {
         this.presentationImagesCount = event.target.files.length;

         Object.keys(event.target.files).forEach(key => {
            const reader = new FileReader()
            reader.onload = () => {
               // the field will be sent on backend as base64 string and apply validations
               this.partnerCompany.presentationImages.push(reader.result);
               this.partnerCompany.presentationImagesUrl.push(reader.result);
            }
            reader.readAsDataURL(event.target.files[key]);
         });
      },
      getNewBranches () {
         this.branchesSelected.forEach(selectedBranch => {
            const branchFound = this.branches.find(item => item.name === selectedBranch.name);
            if (branchFound === undefined) {
               const hasIncluded = this.partnerCompany.newBranches.find(item => item.name === selectedBranch.name);
               if (hasIncluded === undefined)
                  this.partnerCompany.newBranches.push(selectedBranch);
            }
         });
      },
      getNewBenefits () {
         this.benefitsSelected.forEach(selectedBenefit => {
            const benefitFound = this.benefits.find(item => item.name === selectedBenefit.name);
            if (benefitFound === undefined) {
               const hasIncluded = this.partnerCompany.newBenefits.find(item => item.name === selectedBenefit.name);
               if (hasIncluded === undefined)
                  this.partnerCompany.newBenefits.push(selectedBenefit);
            }
         });
      },
      onImageChoose (event) {
         const file = event.target.files[0]

         const reader = new FileReader()
         reader.onload = () => {
            // the field will be sent on backend as base64 string and apply validations
            this.partnerCompany.imageCover = reader.result;
            this.partnerCompany.imageCoverUrl = reader.result;
         }
         reader.readAsDataURL(file);
         this.alreadyIncludeImageCover = true;
      },
      async save() {
         this.errors = [];
         this.isSaving = true;

         try {
            this.partnerCompany.branchesId = [];
            this.branchesSelected.forEach((item) => {
               const hasIncluded = this.partnerCompany.branchesId.find((branch) => branch === item.uuid);
               if (hasIncluded === undefined)
                  this.partnerCompany.branchesId.push(item.uuid);
            });
            this.partnerCompany.benefitsId = [];
            this.benefitsSelected.forEach((item) => {
               const hasIncluded = this.partnerCompany.benefitsId.find((benefit) => benefit === item.uuid);
               if (hasIncluded === undefined)
                  this.partnerCompany.benefitsId.push(item.uuid)
            });

            this.benefitsOptions.forEach((item) => {
               this.partnerCompany.benefitsSelectedToSaveInBenefitCollection.push(item);
            });

            if (this.partnerCompany.imageCover !== null || this.partnerCompany.presentationImages.length > 0)
               this.toast.info(this.$t('starting_upload_images'));

            if (this.partnerCompany.imageCover !== null){
               const imgExtension = this.partnerCompany.imageCover.substring(this.partnerCompany.imageCover.indexOf("/") + 1, this.partnerCompany.imageCover.indexOf(";base64"));
               const imgName = `partnerCompanies/${this.partnerCompany.uuid}/imageCover/${uuidV4()}.${imgExtension}`;

               await storageRef.child(imgName).putString(this.partnerCompany.imageCover, 'data_url');
               this.partnerCompany.imageCoverUrl = await storageRef.child(imgName).getDownloadURL();
            } else {
               if (this.isEditing)
                  this.partnerCompany.imageCoverUrlToDelete = null;
            }

            if (this.partnerCompany.presentationImages.length > 0){
               this.partnerCompany.presentationImagesUrl = this.partnerCompany.presentationImagesUrl.filter(item => !item.startsWith('data'));
               for (const [index, image] of this.partnerCompany.presentationImages.entries()) {
                  const imgExtension = image.substring(this.partnerCompany.presentationImages[index].indexOf("/") + 1, image.indexOf(";base64"));
                  const imgName = `partnerCompanies/${this.partnerCompany.uuid}/presentationImages/${uuidV4()}.${imgExtension}`;

                  await storageRef.child(imgName).putString(this.partnerCompany.presentationImages[index], 'data_url');
                  const imageUrl = await storageRef.child(imgName).getDownloadURL();
                  this.partnerCompany.presentationImagesUrl.push(imageUrl);
               }
            }
            if (this.partnerCompany.imageCover !== null || this.partnerCompany.presentationImages.length > 0)
               this.toast.info(this.$t('the_images_have_been_uploaded_and_we_are_now_saving_the_information'));
            this.partnerCompany.imageCover = null;
            this.partnerCompany.presentationImages = [];

            this.getNewBranches();
            this.getNewBenefits();

            if (this.isEditing){
               await this.getAllPartnerCompaniesBenefitsRelation();
               this.partnerCompany.allPartnerCompaniesRelation = this.allPartnerCompaniesBenefitsRelation;
               await partnerCompanyService.save('edit', this.partnerCompany);
               this.toast.success(this.$t("updated"));
            }
            else{
               await partnerCompanyService.save('create', this.partnerCompany);
               this.toast.success(this.$t("saved"));
            }

            this.$router.push({ name: 'partnerCompanies' });
         } catch (error) {
            const { status } = error.response;
            const errorResponse = error.response.data.errors;

            if (this.isEditing)
               this.toast.error(this.$t("update_failure"));
            else
               this.toast.error(this.$t("save_failure"));

            if (status === 422){
               this.errors = Object.assign(this.errors, errorResponse)
               Object.keys(this.errors).forEach((field) => {
                  this.errors[field].forEach((error) => {
                     this.toast.error(error);
                  });
               });
            }
         }
         finally {
            this.isSaving = false;
         }
      },
      removeBranch (branch) {
         const found = this.branches.find(item => item.name === branch.name);
         if (found === undefined)
            this.branchesOptions = this.branchesOptions.filter(item => item.name !== branch.name);

         this.branchesSelected = this.branchesSelected.filter(item => item.name !== branch.name);
      },
      removeBenefit (benefit) {
         const found = this.benefits.find(item => item.name === benefit.name)
         if (found === undefined)
            this.benefitsOptions = this.benefitsOptions.filter(item => item.name !== benefit.name);

         this.benefitsSelected = this.benefitsSelected.filter(item => item.name !== benefit.name);
      },
      removeSocialMedia(type) {
         this.partnerCompany.socialMedias = this.partnerCompany.socialMedias.filter((item) => item.type !== type);
      },
      showModals() {
         this.showModal = true;
      }
   }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style>
.modal-div {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   width: 182px;
   height: 120px;
   background-color: #dedcdc;
   border-radius: 20px;
   cursor: default;
}

.modal-image {
   position: relative;
   border-radius: 10px;
   cursor: default;
}

.modal-span {
   position: absolute;
   padding-left: 152px;
   padding-bottom: 95px;
   cursor: pointer;
}

.modals {
   font-family: Avenir, Helvetica, Arial, sans-serif;
   text-align: center;
   margin-top: 60px;
}

.modal-mask {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.5);
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 10000;
}

.btn-novo {
   color: #032f34;
   border-radius: 50px;
}

.modal-wrapper {
   box-sizing: border-box;
}

.modal-container {
   width: 650px;
   padding: 20px;
   background: white;
   border-radius: 5px;
   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
   box-sizing: border-box;
}

.modal-header {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   border-bottom: 1px solid #e5e5e5;
   padding-bottom: 10px;
}

.modal-header h3 {
   margin: 0;
}

.modal-header .close {
   position: absolute;
   margin-left: 550px;
   border: none;
   background: none;
   font-size: 1.5em;
   cursor: pointer;
   color: #d9124c;
}

.modal-body {
   display: grid;
   grid-template-columns: 1fr 1fr 1fr;
   gap: 10px;
   margin-bottom: 15px;
}

.upload-btn-wrapper {
   position: relative;
   overflow: hidden;
   display: inline-block;
}

input[type=file] {
   font-size: 100px;
   position: absolute;
   left: 0;
   top: 0;
   opacity: 0;
}
</style>



